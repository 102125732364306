<mat-toolbar
  class="flex align-items-center justify-content-space-between"
  [class.sidenav-opened]="sidenavOpened"
  [class.collapsed]="!sidenavOpened"
>
  <div class="sidenav-footer-section">
    <button class="sidenav-footer-menu-button" mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>unfold_more</mat-icon>
    </button>
    <mat-menu class="sidenav-footer-menu" #menu="matMenu">
      <button *ngIf="isTabletModule && !sessionStart" mat-menu-item (click)="onCheckIn()">
        <mat-icon class="material-icons-outlined" color="accent">arrow_circle_right</mat-icon>
        <span>Start Session</span>
      </button>
      <button *ngIf="isTabletModule && sessionStart" mat-menu-item (click)="onClearSession()">
        <mat-icon class="material-icons-outlined" color="warn">cancel</mat-icon>
        <span>End Session</span>
      </button>
      <button mat-menu-item (click)="onNavigate()">
        <erp-help></erp-help>
      </button>
      <button mat-menu-item (click)="onLogout()">
        <mat-icon>logout</mat-icon>
        <span>Log out</span>
      </button>
    </mat-menu>
    <div *ngIf="sidenavOpened" class="sidenav-footer-text">
      <div class="user-name">{{ userName }}</div>
      <div *ngIf="sessionStart && isTabletModule" class="user-session">
        <ng-container>Session started at </ng-container>
        {{ sessionStart | erpTime }}
      </div>
    </div>
  </div>
  <div class="sidenav-footer-buttons">
    <erp-notification-counter
      class="action-icon"
      [routerLink]="isTabletModule ? '/tablet/notifications' : '/notifications'"
      routerLinkActive="active"
      (click)="onNavigate()"
    ></erp-notification-counter>
    <span class="sidenav-toggle-button" (click)="onToggle()">
      <mat-icon *ngIf="sidenavOpened">arrow_back</mat-icon>
      <mat-icon *ngIf="!sidenavOpened">arrow_forward</mat-icon>
    </span>
  </div>
</mat-toolbar>
