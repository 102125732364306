import { map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseHttpService } from '../abstracts/http/base-http.service';
import {
  IAssociatedInvoiceItem,
  IERPInvoicePatch,
  IInvoice,
  IInvoiceAvaluableTransitions,
  IInvoiceDTO,
  IPreviewSOInvoice
} from '../interfaces';

import { Observable } from 'rxjs';
import { IdValue } from '../types';
import { ERPSharedInvoiceFactory } from './shared-invoice.factory';
import { ICollectionResponse } from '../abstracts';

@Injectable({
  providedIn: 'root'
})
export class ERPSharedInvoicesService extends BaseHttpService {
  constructor(
    readonly httpClient: HttpClient,
    readonly invoiceFactory: ERPSharedInvoiceFactory
  ) {
    super('invoices');
  }

  getInvoiceById(id: number): Observable<IInvoice> {
    return this.get<IInvoiceDTO>({}, id).pipe(map((res: IInvoiceDTO) => this.invoiceFactory.fromResponse(res)));
  }

  createInvoice(invoice: IInvoice): Observable<IInvoice> {
    const request = this.invoiceFactory.toRequest(invoice);

    return this.post<IInvoiceDTO>(request).pipe(map((res: IInvoiceDTO) => this.invoiceFactory.fromResponse(res)));
  }

  updateInvoice(invoice: IInvoice): Observable<IInvoice> {
    const request = this.invoiceFactory.toRequest(invoice);

    return this.put<IInvoiceDTO>(request, {}, request.id as number).pipe(
      map((res: IInvoiceDTO) => this.invoiceFactory.fromResponse(res))
    );
  }

  patchInvoiceStatus(
    status: IERPInvoicePatch<{ status: IdValue }>,
    id: number
  ): Observable<{ message: string } | void> {
    return this.patch(status, {}, id);
  }

  deleteInvoice(id: number): Observable<{ message: string } | void> {
    return this.delete({}, id);
  }

  previewInvoice(id: number): Observable<IPreviewSOInvoice> {
    return this.get<IPreviewSOInvoice>({}, `preview/salesOrder/${id}`);
  }

  getAssociatedInvoices(query: any = {}): Observable<IAssociatedInvoiceItem[]> {
    const params = !!query ? { query } : {};
    return this.get<ICollectionResponse<IAssociatedInvoiceItem>>(params).pipe(
      map((res: ICollectionResponse<IAssociatedInvoiceItem>) => res.data)
    );
  }
}
