import * as HttpStatus from 'http-status';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ERPAuthService } from '@erp/shared';

@Injectable({ providedIn: 'root' })
export class CacheInterceptor implements HttpInterceptor {
  constructor(
    readonly route: ActivatedRoute,
    readonly authService: ERPAuthService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes('assets/icons/action')) {
      const clonedRequest = req.clone({
        setHeaders: {
          'Cache-Control': 'public, max-age=32400',
          Pragma: 'cache'
        }
      });

      return next.handle(clonedRequest).pipe(
        map(event => {
          if (event instanceof HttpResponse && req.url.includes('assets/icons/action')) {
            const clonedResponse = event.clone({
              headers: event.headers.set('Cache-Control', 'public, max-age=32400').set('Pragma', 'cache')
            });
            return clonedResponse;
          }
          return event;
        })
      );
    }

    return next.handle(req);
  }
}
