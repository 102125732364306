import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'erp-table-filter-icon',
  templateUrl: './table-filter-icon.component.html',
  styleUrls: ['./table-filter-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ERPTableFilterIconComponent {
  @Input() readonly active: boolean;
}
