import '@angular/localize/init';

import { capital } from 'case';
import { EMPTY, Observable, merge } from 'rxjs';
import { catchError, filter, takeUntil } from 'rxjs/operators';
import { findBestMatch } from 'string-similarity';

import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  HostListener,
  OnInit
} from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';

import { AutoCleanupFeature, Features, IDestroyable } from '@erp/shared';

import { ERPToasterService } from '../../../toaster';

import { HelpMatchRating } from './help-match.enum';
import { ERPApiConfigService } from '../../../../../../../core/src/lib/services/api-config.service';

@Component({
  selector: 'erp-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([AutoCleanupFeature()])
export class ERPHelpComponent implements OnInit, IDestroyable {
  readonly destroyed$: Observable<unknown>;
  helpAliases: string[] = [];
  helpPageAlias: string | null = null;
  helpMatchRating: HelpMatchRating = HelpMatchRating.Unavailable;
  helpHeaderFileHref = '/docs/HeaderFile.h';

  constructor(
    readonly changeDetector: ChangeDetectorRef,
    readonly httpClient: HttpClient,
    readonly router: Router,
    readonly toaster: ERPToasterService,
    readonly route: ActivatedRoute,
    private readonly apiConfigService: ERPApiConfigService
  ) {}

  get title() {
    return this.helpPageAlias
      ? capital(this.helpPageAlias.replace(/[/-]/g, ' '))
      : $localize`:@@help.errors.failed-to-load:Sorry, we're unable to initialize help system. Please contact support.`;
  }

  get routeData() {
    let firstChild: ActivatedRouteSnapshot | null = this.route.snapshot;

    while (firstChild?.firstChild) {
      firstChild = firstChild.firstChild;
    }

    return {
      data: firstChild?.data,
      params: firstChild?.params
    };
  }

  ngOnInit() {
    this.loadDefinitionFile();
  }

  @HostListener('click')
  onClick() {
    MadCap.OpenHelp(this.helpPageAlias);
  }

  private loadDefinitionFile() {
    this.httpClient
      .get(this.helpHeaderFileHref, {
        responseType: 'text',
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      })
      .pipe(
        catchError(() => {
          this.toaster.error(
            $localize`:@@help.errors.failed-to-load:Sorry, we're unable to initialize help system. Please contact support.`
          );

          return EMPTY;
        })
      )
      .subscribe(response => {
        this.parseDefinitionFileResponse(response);
        this.listenRouterNavigation();
      });
  }

  private parseDefinitionFileResponse(response: string) {
    this.helpAliases = response
      .split('\n')
      .filter(Boolean)
      .map(line => line.trim().split(' '))
      .map(([, alias]) => alias?.toLowerCase())
      .filter(Boolean)
      .map(alias => this.aliasToUrl(alias))
      .sort();
  }

  private listenRouterNavigation() {
    merge(this.router.events.pipe(filter(event => event instanceof NavigationEnd)), this.route.url)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.matchCurrentRoute();
      });
  }

  private matchCurrentRoute() {
    const { data, params } = this.routeData;
    const alias = this.router.url.endsWith(params?.id)
      ? this.router.url.split('?')[0].replace(params?.id, data?.mode)
      : this.router.url.split('?')[0];
    const {
      bestMatch: { rating, target }
    } = findBestMatch(alias, this.helpAliases);

    this.helpPageAlias = this.urlToAlias(target);
    this.helpMatchRating = HelpMatchRating.Questionable;

    if (rating <= HelpMatchRating.Questionable) {
      this.helpMatchRating = HelpMatchRating.Unavailable;
    }

    if (rating > HelpMatchRating.Questionable) {
      this.helpMatchRating = HelpMatchRating.Available;
    }

    this.changeDetector.markForCheck();
  }

  private aliasToUrl(alias: string) {
    return alias.replace(/_{2}/g, '/');
  }
  private urlToAlias(url: string) {
    return url.replace(/\//g, '__');
  }
}
