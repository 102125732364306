<h1 class="mat-headline-5 dialog-header margin-right-5" mat-dialog-title>
  <ng-container i18n="@@logistic.loading-report.popup.header">Select parameters</ng-container>
</h1>
<ng-container>
  <erp-button color="primary" variant="icon" class="dialog-close" mat-dialog-close>
    <erp-icon icon="action:cross"></erp-icon>
  </erp-button>
</ng-container>
<section class="dialog-content" mat-dialog-content>
  <div [formGroup]="form" class="padding-top-3">
    <div>
      <erp-label class="margin-bottom-1" [required]="true">
        <ng-container i18n="@@logistic.loading-report.popup.customer"> Customer: </ng-container>
      </erp-label>
      <erp-autocomplete
        formControlName="customerName"
        icon="action:search"
        [autoSelect]="true"
        (changed)="onCustomerChanged($event)"
        [optionsFn]="customerOptionsFn"
        [labelFn]="customerLabelFn"
        [displayFn]="customerLabelFn"
      ></erp-autocomplete>
    </div>
    <erp-label class="margin-bottom-1" [required]="true">
      <ng-container i18n="@@logistic.loading-report.popup.receiving-date">Date of the On-hand:</ng-container>
    </erp-label>
    <div class="">
      <erp-date-picker class="col-5" formControlName="date" [clearable]="true"></erp-date-picker>
    </div>
  </div>
</section>
<section class="flex justify-content-flex-end" mat-dialog-actions>
  <erp-button color="primary" class="margin-right-4" variant="link" mat-dialog-close>
    <ng-container i18n="@@common.buttons.cancel"> Cancel </ng-container>
  </erp-button>
  <erp-button [disabled]="!form.valid" class="dialog-action-confirm" color="accent" (click)="onCreate()">
    <ng-container i18n="@@common.buttons.create"> Create </ng-container>
  </erp-button>
</section>
