<div class="filtered-by flex align-items-baseline" *ngIf="filters.length">
  <mat-chip-list>
    <mat-chip
      *ngFor="let filter of filters; let i = index; trackBy: trackByFn"
      color="primary"
      selected
      removable
      (removed)="onRemove(i)"
      [erpTooltip]="'System filter'"
      [erpTooltipDisabled]="isNotSystem(filter)"
    >
      <span>
        {{ getDisplayName(filter) }}
      </span>
      <span *ngIf="isNotSystem(filter)" class="flex justify-content-flex-end align-items-center" matChipRemove>
        &#10005;
      </span>
    </mat-chip>
    <erp-button
      class="margin-left-2"
      color="primary"
      variant="link"
      *ngIf="filters.length > 1"
      (click)="onRemoveAll()"
      i18n="@@common.table.filters.clear-all"
    >
      Clear all
    </erp-button>
  </mat-chip-list>
</div>
