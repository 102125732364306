export const PIPE_MATERIAL_TYPE_IDS = [
  2, // casing,
  1, // tubing,
  4, // line pipe
  5, // drill pipe
  7, // markers
  8, // pup joints
  15, // cross over
  16, // coupling stock
  18 // coupling material
];

export const isPipe = (materialTypeId: number) => {
  return PIPE_MATERIAL_TYPE_IDS.includes(materialTypeId);
};
export const isAccessory = (materialTypeId: number) => {
  return !PIPE_MATERIAL_TYPE_IDS.includes(materialTypeId);
};
