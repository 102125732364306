import '@angular/localize/init';

import { Observable, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';

import {
  AutoCleanupFeature,
  BaseListComponent,
  ERPFormStateDispatcher,
  ERPStockService,
  ERP_SMALL_TABLE_PAGING,
  Features,
  ICollectionResponseMetadata,
  IDestroyable,
  IMetadataFiltering,
  IMetadataPaging,
  ISalesQuoteAllocatedMaterial,
  IdValue,
  Memoise,
  TableQueryBuilder
} from '@erp/shared';

import { ISalesQuoteAllocatedMaterialsDialogData } from '../interfaces/allocated-materails-dialog-data.interface';

@Features([AutoCleanupFeature()])
@Component({
  selector: 'erp-sales-sales-quote-materials-allocated-material',
  templateUrl: './sales-quote-materials-allocated-material.component.html',
  styleUrls: ['./sales-quote-materials-allocated-material.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ERPFormStateDispatcher]
})
@Features([AutoCleanupFeature()])
export class ERPSalesQuoteMaterialsAllocatedMaterialComponent<T extends ISalesQuoteAllocatedMaterial>
  extends BaseListComponent<T>
  implements OnInit, IDestroyable
{
  readonly destroyed$: Observable<void>;
  tableData: ISalesQuoteAllocatedMaterial[];
  readonly aliasesMap = new Map<keyof T, unknown[]>();
  readonly hiddenFilters = new Set(['skuId', 'materiallId', 'typeId']);
  readonly salesOrStockList: IdValue[] = [
    { id: 1, value: $localize`:@@sales-quote.allocated-material.sales:Sales` },
    { id: 2, value: $localize`:@@sales-quote.allocated-material.stock:Stock` }
  ];
  readonly defaultQtyFilter: IMetadataFiltering = { by: 'qty', match1: 0, op: 'gt' };

  constructor(
    readonly changeDetector: ChangeDetectorRef,
    readonly router: Router,
    readonly route: ActivatedRoute,
    readonly dialog: MatDialog,
    readonly dialogRef: MatDialogRef<ERPSalesQuoteMaterialsAllocatedMaterialComponent<T>>,
    @Inject(MAT_DIALOG_DATA) readonly dialogData: ISalesQuoteAllocatedMaterialsDialogData,
    readonly stockService: ERPStockService,
    @Inject(ERP_SMALL_TABLE_PAGING)
    readonly paging: IMetadataPaging
  ) {
    super();

    this.query = TableQueryBuilder.from({ paging: { ...this.paging } }).build();
  }

  get query$() {
    return of(this.query);
  }

  @Memoise()
  get selectedAvailableMaterial() {
    return this.dialogData.selectedAvailableMaterial;
  }

  @Memoise()
  get line() {
    return this.dialogData.line;
  }

  @Memoise()
  get salesDocumentTypes() {
    return this.dialogData.salesDocumentTypes;
  }

  ngOnInit() {
    this.aliasesMap.set('salesOrStock', this.salesOrStockList);
    this.aliasesMap.set('salesDocumentTypeId', this.salesDocumentTypes);

    this.dialogRef
      .backdropClick()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.onCancel();
      });
    super.onInit();
    this.onQueryInit();
  }

  onCancel() {
    this.dialogRef.close();
  }

  onQueryInit() {
    // RTED-3530
    const defaultTypeId = 2;
    const skuId = this.selectedAvailableMaterial.sku;
    const materiallId = this.selectedAvailableMaterial.material?.id;
    const filtering = [
      { by: 'skuId', match1: skuId, op: 'eq' },
      { by: 'materiallId', match1: materiallId, op: 'eq' },
      { by: 'typeId', match1: defaultTypeId, op: 'eq' },
      this.defaultQtyFilter
    ] as IMetadataFiltering[];

    this.query.filtering = filtering;

    const query = TableQueryBuilder.from(this.query).build() as ICollectionResponseMetadata;

    this.onQueryChanged(query);
    this.query = this.removeDefaultQtyFiltering(query);
  }

  onQueryChanged(query: ICollectionResponseMetadata) {
    const defaultLineParams = this.line
      ? {
          displayUomId: this.line?.uomId,
          baseSkuId: this.line?.sku
        }
      : {};

    this.stockService
      .getAllocatedTransactions({ query: TableQueryBuilder.from(query).serialize(), ...defaultLineParams })
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        this.tableData = res.data;
        this.query = this.removeDefaultQtyFiltering(res.metadata);
        this.changeDetector.markForCheck();
      });
  }

  private removeDefaultQtyFiltering(query: ICollectionResponseMetadata): ICollectionResponseMetadata<T> {
    query.filtering = query?.filtering.filter(
      (f: IMetadataFiltering) => f.by !== this.defaultQtyFilter.by && f.op !== this.defaultQtyFilter.op
    );

    return query;
  }
}
