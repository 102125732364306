import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ERPIconModule } from '../icon';
import { ERPWorkflowProgressBarComponent } from './components';

@NgModule({
  declarations: [ERPWorkflowProgressBarComponent],
  imports: [CommonModule, ERPIconModule],
  exports: [ERPWorkflowProgressBarComponent]
})
export class ERPWorkflowProgressBarModule {}
