import { ChangeDetectionStrategy, Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { AutoCleanupFeature, BaseFormComponent, Features, ValidatorFeature, ValueAccessorFeature } from '@erp/shared';
import { ISelectOption } from '../../../modules/index';

@Component({
  selector: 'erp-document-status-change-confirm',
  templateUrl: './document-status-change-confirm.component.html',
  styleUrls: ['./document-status-change-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([AutoCleanupFeature(), ValueAccessorFeature(), ValidatorFeature()])
export class ERPDocumentStatusChangeConfirm<T> extends BaseFormComponent<T> implements OnInit {
  readonly form = new UntypedFormGroup({
    reason: new UntypedFormControl(null)
  });
  message: string;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    readonly data: { message: string; status: ISelectOption },
    readonly dialogRef: MatDialogRef<ERPDocumentStatusChangeConfirm<T>>
  ) {
    super();
  }

  ngOnInit(): void {
    this.message = this.data?.message;
  }

  onConfirm() {
    const { value } = this.form;

    this.dialogRef.close({
      reason: value.reason ?? ''
    });
  }
}
