import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseHttpService, ICollectionResponse, ICollectionResponseMetadata } from '../abstracts';
import { ILocation, ILocationResponse, IRack } from '../interfaces';
import { PropType } from '../types';

import { ERPLocationFactory } from './location.factory';

@Injectable({ providedIn: 'root' })
export class ERPInventoryLocationService extends BaseHttpService {
  constructor(
    readonly httpClient: HttpClient,
    readonly factory: ERPLocationFactory
  ) {
    super('locations');
  }

  getLocation(id: number) {
    return this.get<ILocation>(undefined, id);
  }

  getRacks(params: object): Observable<ICollectionResponse<IRack>> {
    return this.get<ICollectionResponse<IRack>>(params, 'racks');
  }

  getLocationNames(searchString: PropType<ILocation, 'locationId'>) {
    return this.get<PropType<ILocation, 'locationId'>[]>({ searchString }, 'names');
  }

  getLocations(params: object, skipLoader: boolean = false): Observable<ICollectionResponse<ILocationResponse>> {
    const options = skipLoader ? { headers: { 'skip-loader': 'true' } } : {};
    return this.get<ICollectionResponse<ILocationResponse>>(params, '', options);
  }

  createLocation(location: ILocation) {
    const request = this.factory.toRequest(location);

    return this.post<ILocationResponse>(request).pipe(map(response => this.factory.fromResponse(response)));
  }

  updateLocation(location: ILocation) {
    const { id } = location;
    const request = this.factory.toRequest(location);

    return this.put<ILocationResponse>(request, undefined, id).pipe(
      map(response => this.factory.fromResponse(response))
    );
  }

  deleteLocation(id: PropType<ILocation, 'id'>) {
    return this.delete<void>(undefined, id);
  }
}
