<mat-sidenav-container [hasBackdrop]="false" class="flex justify-content-between" [class.sidenav-collapsed]="collapsed">
  <mat-sidenav #sidenav class="animated fadeIn fast flex" color="dark-graphite" fixedInViewport opened>
    <mat-toolbar class="flex sticky-header">
      <div class="flex align-items-center justify-content-space-between sidenav-opened">
        <a class="logo" [routerLink]="isTabletModule ? '/tablet' : '/'">
          <erp-icon icon="logo:large" size="auto"></erp-icon>
        </a>
      </div>
    </mat-toolbar>

    <aside>
      <ng-content select="[navigation]" [class.sidenav-opened]="!collapsed"></ng-content>
    </aside>

    <erp-sidenav-footer
      class="flex sticky-footer"
      [isTabletModule]="isTabletModule"
      [sidenavOpened]="!collapsed"
      (toggled)="onToggle()"
    ></erp-sidenav-footer>
  </mat-sidenav>

  <mat-sidenav-content class="animated fadeIn fast" (click)="onContentClick()">
    <ng-content select="[content]"></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
