<header class="dialog-header" mat-dialog-header>
  <erp-toolbar class="flex flex-auto flex-column">
    <div class="flex justify-content-space-between">
      <h1 class="dialog-title flex-grow-1" mat-dialog-title>
        <ng-content select="[header]"></ng-content>
      </h1>
      <div class="dialog-close flex-shrink-1">
        <ng-container *ngIf="closable">
          <erp-button color="primary" variant="icon" mat-dialog-close>
            <erp-icon icon="action:cross"></erp-icon>
          </erp-button>
        </ng-container>
        <ng-content select="[close]"></ng-content>
      </div>
    </div>
  </erp-toolbar>
</header>
<section class="dialog-content flex-auto" mat-dialog-content>
  <ng-content select="[content]"></ng-content>
</section>
<footer class="dialog-footer" mat-dialog-actions>
  <erp-toolbar class="flex-auto side-footer" [borderTop]="footerBorder">
    <div class="flex justify-content-flex-end">
      <ng-content select="[footer]"></ng-content>
    </div>
  </erp-toolbar>
</footer>
