<table class="animated fadeIn" mat-table erpResizeTable [dataSource]="dataSource" matSort>
  <ng-container matColumnDef="documentNumber">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <p
        i18n="@@sales.sales-quotes.allocated-material.transactions.documentNumber"
        [erpTooltipForTruncatedText]="true"
        erpTooltip="Transaction ID"
      >
        Transaction ID
      </p>
      <erp-table-filter-icon
        [active]="hasFilter('documentNumber')"
        (click)="onFilterOpen($event, 'documentNumber', 'string-contains')"
      ></erp-table-filter-icon>
    </th>
    <td mat-cell *matCellDef="let element">
      <a
        [routerLink]="[getLink(element.documentTypeId), element.documentId]"
        target="_blank"
        (click)="$event.stopPropagation()"
      >
        {{ element.documentNumber }}
      </a>
    </td>
  </ng-container>

  <ng-container matColumnDef="qty">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <p
        i18n="@@sales.sales-quotes.allocated-material.transactions.allocatedQty"
        [erpTooltipForTruncatedText]="true"
        erpTooltip="Allocated"
      >
        Allocated
      </p>
      <erp-table-filter-icon
        [active]="hasFilter('qty')"
        (click)="onFilterOpen($event, 'qty', 'numeric-decimal-range')"
      ></erp-table-filter-icon>
    </th>

    <td mat-cell *matCellDef="let element" class="text-end">{{ element.qty }}</td>
  </ng-container>

  <ng-container matColumnDef="salesDocumentTypeName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <p
        i18n="@@sales.sales-quotes.allocated-material.transactions.documentType"
        [erpTooltipForTruncatedText]="true"
        erpTooltip="Document type"
      >
        Document type
      </p>
      <erp-table-filter-icon
        [active]="hasFilter('salesDocumentTypeId')"
        (click)="onFilterOpen($event, 'salesDocumentTypeId', 'multi-select', salesDocumentTypes)"
      ></erp-table-filter-icon>
    </th>

    <td
      mat-cell
      *matCellDef="let element"
      class="text-end"
      [ngClass]="element.firstRightOfRefusal === true || element.salesDocumentTypeName === 'Quote' ? 'align-icon' : ''"
    >
      <div class="flex align-items-center justify-content-space-between">
        <p class="right-text">{{ element.salesDocumentTypeName }}</p>
        &nbsp;
        <ng-container *ngIf="element.firstRightOfRefusal === true || element.salesDocumentTypeName === 'Quote'">
          <erp-icon
            class="error-fror-icon"
            color="primary"
            icon="action:error_fror"
            erpTooltip="Reservation is not firm."
          ></erp-icon>
        </ng-container>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="uom">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <p
        i18n="@@sales.sales-quotes.allocated-material.transactions.uom"
        [erpTooltipForTruncatedText]="true"
        erpTooltip="UOM"
      >
        UOM
      </p>
      <erp-table-filter-icon
        [active]="hasFilter('uom')"
        (click)="onFilterOpen($event, 'uom', 'string-contains')"
      ></erp-table-filter-icon>
    </th>

    <td mat-cell *matCellDef="let element">{{ element.uom }}</td>
  </ng-container>

  <ng-container matColumnDef="ownerName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <p
        i18n="@@sales.sales-quotes.allocated-material.transactions.salesRep"
        [erpTooltipForTruncatedText]="true"
        erpTooltip="Sales Representative"
      >
        Sales Representative
      </p>
      <erp-table-filter-icon
        [active]="hasFilter('ownerName')"
        (click)="onFilterOpen($event, 'ownerName', 'string-contains')"
      ></erp-table-filter-icon>
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.ownerName }}
    </td>
  </ng-container>

  <ng-container matColumnDef="salesOrStock">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <p
        i18n="@@sales.sales-quotes.allocated-material.transactions.salesOrStock"
        [erpTooltipForTruncatedText]="true"
        erpTooltip="Order Type"
      >
        Order Type
      </p>
      <erp-table-filter-icon
        [active]="hasFilter('salesOrStock')"
        (click)="onFilterOpen($event, 'salesOrStock', 'multi-select', salesOrStockList)"
      ></erp-table-filter-icon>
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element?.salesOrStock | erpFindProp: salesOrStockList }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns" color="primary"></tr>

  <tr mat-row class="cursor-pointer" *matRowDef="let row; columns: columns"></tr>
</table>

<div
  *ngIf="!dataSource.data.length"
  class="erp-table-nothing-found margin-top-8 text-center"
  i18n="@@common.table.no-matches"
>
  No matches for your filtering criteria
</div>
