import { Observable, switchMap } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseHttpService, ICollectionResponse } from '../abstracts';
import { IMaterial, IMaterialsResponse } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ERPMaterialsService extends BaseHttpService {
  constructor(readonly httpClient: HttpClient) {
    super('materials');
  }

  getMaterials(params: object, skipLoader: boolean = false): Observable<ICollectionResponse<IMaterialsResponse>> {
    const options = skipLoader ? { headers: { 'skip-loader': 'true' } } : {};
    return this.get<ICollectionResponse<IMaterialsResponse>>(params, 'reference', options);
  }

  getExternalNumbers(searchString: string): Observable<string[]> {
    return this.get<string[]>({ searchString }, 'externalNumbers');
  }

  getVesselName(params: object): Observable<string[]> {
    return this.get<string[]>(params, 'vesselNames');
  }

  mtr = {
    getMTRs: (materialId: number) => {
      return this.get<any>({}, `${materialId}/mtrs`);
    },
    getMTR: (materialId: number, mtrId: number) => {
      return this.get<any>({}, `${materialId}/mtrs/${mtrId}`, { responseType: 'blob' });
    },
    createMTR: (file: FormData, materialId: number) => {
      return this.post<FormData>(file, { force: true }, `${materialId}/mtrs`, {
        reportProgress: true,
        observe: 'events'
      });
    },
    deleteMTR: (materialId: number, mtrId: number) => {
      return this.delete<any>({}, `${materialId}/mtrs/${mtrId}`);
    },
    getHeats: (materialId: number, mtrId: number) => {
      return this.get<any>({}, `${materialId}/mtrs/${mtrId}/heats`);
    },
    createHeats: (materialId: number, mtrId: number, heats: string[]) => {
      return this.post<any>(heats, {}, `${materialId}/mtrs/${mtrId}/heats`);
    },
    updateHeat: (materialId: number, mtrId: number, oldHeat: string, newHeat: string) => {
      return this.mtr.deleteHeat(materialId, mtrId, oldHeat).pipe(
        switchMap(() => {
          return this.mtr.createHeats(materialId, mtrId, [newHeat]);
        })
      );
    },
    deleteHeat: (materialId: number, mtrId: number, heat: string) => {
      return this.delete<any>({}, `${materialId}/mtrs/${mtrId}/heats/${heat}`);
    }
  };

  editMaterial(request: IMaterial, id: number): Observable<IMaterial> {
    return this.put<IMaterial>(request, {}, id);
  }

  getMaterial(id: number): Observable<IMaterial> {
    return this.get<IMaterial>({}, id);
  }
}
