<erp-side-dialog>
  <div header>
    <ng-container i18n="@@sales.sales-quotes.allocated-material.title">Review Allocated Materials</ng-container>
  </div>
  <div close>
    <erp-button color="primary" variant="icon" (click)="onCancel()">
      <erp-icon icon="action:cross"></erp-icon>
    </erp-button>
  </div>
  <div content>
    <erp-sales-sales-quote-materials-allocated-selected-material
      [allocatedMaterial]="selectedAvailableMaterial"
    ></erp-sales-sales-quote-materials-allocated-selected-material>

    <div class="grid margin-top-3">
      <div class="col-6">
        <h2 i18n="@@sales.sales-quotes.materials.allocated-transactions.header" class="list-header">
          List of Transactions
        </h2>
      </div>
    </div>

    <erp-filtered-by
      [aliasesMap]="aliasesMap"
      [hideFiltersSet]="hiddenFilters"
      [filtering]="query?.filtering"
      (filterChange)="onFilteringChanged($event)"
    ></erp-filtered-by>
    <erp-sales-sales-quote-materials-allocated-transactions-table
      [dialogRef]="dialogRef"
      [tableData]="tableData"
      [salesOrStockList]="salesOrStockList"
      [filtering]="query?.filtering"
      [sorting]="query?.sorting"
      [salesDocumentTypes]="salesDocumentTypes"
      (filteringChange)="onFilteringChanged($event)"
      (sortingChange)="onSortingChanged($event)"
    ></erp-sales-sales-quote-materials-allocated-transactions-table>
    <div class="col-12 flex justify-content-flex-end margin-top-4">
      <erp-paginator
        [page]="query?.paging.page"
        [pageSize]="query?.paging.perPage"
        [total]="query?.paging.total"
        (pageChange)="onPageChanged($event)"
      ></erp-paginator>
    </div>
  </div>
  <div footer>
    <erp-button wide (click)="onCancel()">
      <ng-container i18n="@@common.buttons.close"> Close </ng-container>
    </erp-button>
  </div>
</erp-side-dialog>

<ng-template>
  <ng-container i18n="@@sales-quote.allocated-material.sales"> Sales </ng-container>
  <ng-container i18n="@@sales-quote.allocated-material.stock"> Stock </ng-container>
</ng-template>
