export enum ERPPurchaseOrderAttachmentsCategory {
  PurchaseOrder = 7,
  OrderConfirmation = 38,
  Invoices,
  POReceiver,
  Receipts,
  Approvals,
  Other
}

export enum ERPPurchaseOrderLineStatus {
  Active = 1,
  Inactive = 2,
  Deleted = 3,
  System = 4
}
