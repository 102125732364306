<table
  mat-table
  erpResizeTable
  erpTableOrder
  [(erpOrderColumns)]="columns"
  [dataSource]="dataSource"
  [@.disabled]="true"
  matSort
  [formGroup]="form"
>
  <ng-container matColumnDef="materialType">
    <th class="table-cell-material-type" mat-header-cell *matHeaderCellDef mat-sort-header>
      <p>
        <span *ngIf="editableRow !== -1">*</span>
        <ng-container i18n="@@sales.materials.material-type"> Material Type </ng-container>
      </p>
    </th>
    <td class="with-padding table-cell-material-type" mat-cell *matCellDef="let index" [formGroupName]="index">
      {{ form.at(index)?.get('materialType')?.value.value }}
    </td>
  </ng-container>
  <ng-container matColumnDef="sku">
    <th class="table-cell-sku" mat-header-cell *matHeaderCellDef mat-sort-header>
      <p>
        <span *ngIf="editableRow !== -1">*</span>
        <ng-container i18n="@@sales.materials.sku"> SKU </ng-container>
      </p>
      <erp-table-filter-icon
        [active]="hasFilter('sku')"
        (click)="onFilterOpen($event, 'sku', 'string-contains')"
      ></erp-table-filter-icon>
    </th>
    <td class="with-padding table-cell-sku" mat-cell *matCellDef="let index" [formGroupName]="index">
      <a
        [routerLink]="[getLink(form.at(index)?.get('sku')?.value), form.at(index)?.get('sku')?.value]"
        (click)="$event.stopPropagation()"
      >
        {{ form.at(index)?.get('sku')?.value }}
      </a>
    </td>
  </ng-container>
  <ng-container matColumnDef="skuDescription">
    <th class="table-cell-sku-description" mat-header-cell *matHeaderCellDef mat-sort-header>
      <p>
        <span *ngIf="editableRow !== -1">*</span>
        <ng-container i18n="@@sales.materials.sku-description"> SKU Description </ng-container>
      </p>
      <erp-table-filter-icon
        [active]="hasFilter('skuDescription')"
        (click)="onFilterOpen($event, 'skuDescription', 'string-contains')"
      ></erp-table-filter-icon>
    </th>
    <td class="with-padding" mat-cell *matCellDef="let index" [formGroupName]="index">
      {{ form.at(index)?.get('skuDescription')?.value }}
    </td>
  </ng-container>
  <ng-container matColumnDef="material">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <p i18n="@@sales.materials.material-id">Material ID</p>
      <erp-table-filter-icon
        [active]="hasFilter('material')"
        (click)="onFilterOpen($event, 'material', 'string-contains')"
      ></erp-table-filter-icon>
    </th>
    <td class="with-padding" mat-cell *matCellDef="let index" [formGroupName]="index">
      <a
        [routerLink]="[salesLinks.MaterialIdHistory]"
        [queryParams]="{ query: getMaterialIDquery(index) }"
        (click)="$event.stopPropagation()"
      >
        {{ form.at(index)?.get('material')?.value?.materialNumber }}
      </a>
    </td>
  </ng-container>
  <ng-container matColumnDef="mill">
    <th class="table-cell-mill" mat-header-cell *matHeaderCellDef mat-sort-header>
      <p i18n="@@sales.materials.mill">Mill</p>
      <erp-table-filter-icon
        [active]="hasFilter('mill')"
        (click)="onFilterOpen($event, 'mill', 'string-contains')"
      ></erp-table-filter-icon>
    </th>
    <td class="with-padding table-cell-mill" mat-cell *matCellDef="let index" [formGroupName]="index">
      <a
        *ngIf="form.at(index)?.get('mill')?.value?.id !== 1; else noLinkData"
        [routerLink]="[salesLinks.Manufacturer, form.at(index)?.get('mill')?.value?.id]"
        (click)="$event.stopPropagation()"
      >
        {{ form.at(index)?.get('mill')?.value?.name }}
      </a>
      <ng-template #noLinkData>
        {{ form.at(index)?.get('mill')?.value?.name }}
      </ng-template>
    </td>
  </ng-container>

  <ng-container matColumnDef="ownerName">
    <th class="table-cell-mill" mat-header-cell *matHeaderCellDef mat-sort-header>
      <p i18n="@@sales.materials.owner">Owner</p>
      <erp-table-filter-icon
        [active]="hasFilter('ownerName')"
        (click)="onFilterOpen($event, 'ownerName', 'string-contains')"
      ></erp-table-filter-icon>
    </th>
    <td class="with-padding table-cell-mill" mat-cell *matCellDef="let index" [formGroupName]="index">
      <a
        [routerLink]="[salesLinks.Customers, form.at(index)?.get('ownerName')?.value?.id]"
        (click)="$event.stopPropagation()"
      >
        {{ form.at(index)?.get('ownerName')?.value?.customerName }}
      </a>
      <ng-template #noLinkData>
        {{ form.at(index)?.get('ownerName')?.value?.customerName }}
      </ng-template>
    </td>
  </ng-container>

  <ng-container matColumnDef="inventoryStatus">
    <th class="table-cell-mill" mat-header-cell *matHeaderCellDef mat-sort-header>
      <p i18n="@@sales.materials.inventory-status">Inventory Status</p>
      <erp-table-filter-icon
        [active]="hasFilter('inventoryStatusId')"
        (click)="onFilterOpen($event, 'inventoryStatusId', 'multi-select', inventoryStatuses)"
      ></erp-table-filter-icon>
    </th>
    <td class="with-padding" mat-cell *matCellDef="let index" [formGroupName]="index">
      {{ form.at(index)?.get('inventoryStatus')?.value?.value }}
    </td>
  </ng-container>
  <ng-container matColumnDef="processor">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <p i18n="@@sales.materials.processor">Processor</p>
      <erp-table-filter-icon
        [active]="hasFilter('processor')"
        (click)="onFilterOpen($event, 'processor', 'string-contains')"
      ></erp-table-filter-icon>
    </th>
    <td class="with-padding" mat-cell *matCellDef="let index" [formGroupName]="index">
      <a
        [routerLink]="[salesLinks.Manufacturer, form.at(index)?.get('processor')?.value?.id]"
        (click)="$event.stopPropagation()"
      >
        {{ form.at(index)?.get('processor')?.value?.name }}
      </a>
    </td>
  </ng-container>
  <ng-container matColumnDef="receiptDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <p i18n="@@sales.materials.receipt-date">Receipt Date</p>
      <erp-table-filter-icon
        [active]="hasFilter('receiptDate')"
        (click)="onFilterOpen($event, 'receiptDate', 'date-range')"
      ></erp-table-filter-icon>
    </th>
    <td class="with-padding" mat-cell *matCellDef="let index" [formGroupName]="index">
      {{ form.at(index)?.get('receiptDate')?.value | erpDate }}
    </td>
  </ng-container>
  <ng-container matColumnDef="qtyClaims">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <p i18n="@@sales.materials.quantity-claims">QTY Claims</p>
      <erp-table-filter-icon
        [active]="hasFilter('qtyClaims')"
        (click)="onFilterOpen($event, 'qtyClaims', 'numeric-decimal-range')"
      ></erp-table-filter-icon>
    </th>
    <td class="with-padding" mat-cell *matCellDef="let index" [formGroupName]="index">
      {{ form.at(index)?.get('qtyClaims')?.value }}
    </td>
  </ng-container>
  <ng-container matColumnDef="qltClaims">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <p i18n="@@sales.materials.quality-claims">QLTY Claims</p>
      <erp-table-filter-icon
        [active]="hasFilter('qltClaims')"
        (click)="onFilterOpen($event, 'qltClaims', 'numeric-decimal-range')"
      ></erp-table-filter-icon>
    </th>
    <td class="with-padding" mat-cell *matCellDef="let index" [formGroupName]="index">
      {{ form.at(index)?.get('qltClaims')?.value }}
    </td>
  </ng-container>
  <ng-container matColumnDef="site">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <p i18n="@@sales.materials.site">Facility</p>
      <erp-table-filter-icon
        [active]="hasFilter('site')"
        (click)="onFilterOpen($event, 'site', 'string-contains')"
      ></erp-table-filter-icon>
    </th>
    <td class="with-padding" mat-cell *matCellDef="let index" [formGroupName]="index">
      <a [routerLink]="[salesLinks.Site, form.at(index)?.get('site')?.value?.id]" (click)="$event.stopPropagation()">
        {{ form.at(index)?.get('site')?.value?.name }}
      </a>
    </td>
  </ng-container>

  <!-- Available (EA) -->
  <ng-container matColumnDef="availableQtyEa">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <p i18n="@@sales.materials.available-ea">Available (EA)</p>
      <erp-table-filter-icon
        [active]="hasFilter('availableQtyEa')"
        (click)="onFilterOpen($event, 'availableQtyEa', 'numeric-decimal-range')"
      ></erp-table-filter-icon>
    </th>
    <td class="with-padding" mat-cell *matCellDef="let index" [formGroupName]="index">
      {{ form.at(index)?.get('availableQtyEa')?.value }}
    </td>
  </ng-container>

  <!-- Available (FT) -->
  <ng-container matColumnDef="availableQtyFt">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <p i18n="@@sales.materials.available-ft">Available (FT)</p>
      <erp-table-filter-icon
        [active]="hasFilter('availableQtyFt')"
        (click)="onFilterOpen($event, 'availableQtyFt', 'numeric-decimal-range')"
      ></erp-table-filter-icon>
    </th>
    <td class="with-padding" mat-cell *matCellDef="let index" [formGroupName]="index">
      {{ form.at(index)?.get('availableQtyFt')?.value }}
    </td>
  </ng-container>

  <ng-container matColumnDef="allocatedEA">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <p i18n="@@sales.materials.allocated-ea">Allocated (EA)</p>
      <erp-table-filter-icon
        [active]="hasFilter('allocatedEA')"
        (click)="onFilterOpen($event, 'allocatedEA', 'numeric-decimal-range')"
      ></erp-table-filter-icon>
    </th>
    <td
      class="with-padding text-end"
      [ngClass]="form.at(index)?.get('firstRightOfRefusal')?.value === true ? 'align-icon text-end' : ''"
      mat-cell
      *matCellDef="let index"
      [formGroupName]="index"
    >
      <ng-container *ngIf="form.at(index)?.get('firstRightOfRefusal')?.value === true">
        <erp-icon
          class="error-fror-icon"
          color="primary"
          icon="action:error_fror"
          erpTooltip="There are not firm reservations."
        ></erp-icon>
      </ng-container>
      <a class="cursor-pointer white-space-no-wrap" (click)="onViewMaterials(index)">
        {{ form.at(index)?.get('allocatedEA')?.value }}
      </a>
    </td>
  </ng-container>

  <ng-container matColumnDef="allocatedFT">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <p i18n="@@sales.materials.allocated-ea">Allocated (FT)</p>
      <erp-table-filter-icon
        [active]="hasFilter('allocatedFT')"
        (click)="onFilterOpen($event, 'allocatedFT', 'numeric-decimal-range')"
      ></erp-table-filter-icon>
    </th>
    <td
      class="with-padding text-end"
      [ngClass]="form.at(index)?.get('firstRightOfRefusal')?.value === true ? 'align-icon text-end' : ''"
      mat-cell
      *matCellDef="let index"
      [formGroupName]="index"
    >
      <ng-container *ngIf="form.at(index)?.get('firstRightOfRefusal')?.value === true">
        <erp-icon
          class="error-fror-icon"
          color="primary"
          icon="action:error_fror"
          erpTooltip="There are not firm reservations."
        ></erp-icon>
      </ng-container>
      <a class="cursor-pointer white-space-no-wrap" (click)="onViewMaterials(index)">
        {{ form.at(index)?.get('allocatedFT')?.value }}
      </a>
    </td>
  </ng-container>
  <ng-container matColumnDef="availabilityType">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <p i18n="@@sales.materials.availability-type">Availability Type</p>
      <erp-table-filter-icon
        [active]="hasFilter('availabilityType')"
        (click)="onFilterOpen($event, 'availabilityType', 'multi-select', availabilityTypes)"
      ></erp-table-filter-icon>
    </th>
    <td class="with-padding" mat-cell *matCellDef="let index" [formGroupName]="index">
      {{ form.at(index)?.get('availabilityType')?.value }}
    </td>
  </ng-container>
  <ng-container matColumnDef="inTransit">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <p i18n="@@sales.materials.in-transit">In Transit</p>
      <erp-table-filter-icon
        [active]="hasFilter('inTransit')"
        (click)="onFilterOpen($event, 'inTransit', 'numeric-decimal-range')"
      ></erp-table-filter-icon>
    </th>
    <td class="with-padding" mat-cell *matCellDef="let index" [formGroupName]="index">
      {{ form.at(index)?.get('inTransit')?.value }}
    </td>
  </ng-container>

  <ng-container matColumnDef="processingDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <p i18n="@@sales.materials.processin-date">Processing Date</p>
      <erp-table-filter-icon
        [active]="hasFilter('processingDate')"
        (click)="onFilterOpen($event, 'processingDate', 'date-range')"
      ></erp-table-filter-icon>
    </th>
    <td class="with-padding" mat-cell *matCellDef="let index" [formGroupName]="index">
      {{ form.at(index)?.get('processingDate')?.value | erpDate }}
    </td>
  </ng-container>

  <ng-container matColumnDef="entryDocumentNumber">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <p i18n="@@sales.materials.purchase-order">Purchase Order</p>
      <erp-table-filter-icon
        [active]="hasFilter('entryDocumentNumber')"
        (click)="onFilterOpen($event, 'entryDocumentNumber', 'string-contains')"
      ></erp-table-filter-icon>
    </th>
    <td class="with-padding" mat-cell *matCellDef="let index" [formGroupName]="index">
      <a
        [routerLink]="[
          '/procurement/inventory-transactions/purchase-orders',
          form.at(index)?.get('entryDocumentId')?.value
        ]"
        target="_blank"
        (click)="$event.stopPropagation()"
      >
        {{ form.at(index)?.get('entryDocumentNumber')?.value }}
      </a>
    </td>
  </ng-container>

  <ng-container matColumnDef="vesselName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <p i18n="@@sales.materials.vessel">Vessel</p>
      <erp-table-filter-icon
        [active]="hasFilter('vesselName')"
        (click)="onFilterOpen($event, 'vesselName', 'string-contains')"
      ></erp-table-filter-icon>
    </th>
    <td class="with-padding" mat-cell *matCellDef="let index" [formGroupName]="index">
      <a (click)="$event.stopPropagation()">
        {{ form.at(index)?.get('vesselName')?.value }}
      </a>
    </td>
  </ng-container>

  <ng-container matColumnDef="costEA">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <p i18n="@@sales.materials.cost-ea">Cost (EA)</p>
      <erp-table-filter-icon
        [active]="hasFilter('costEA')"
        (click)="onFilterOpen($event, 'costEA', 'numeric-decimal-range')"
      ></erp-table-filter-icon>
    </th>
    <td class="with-padding" mat-cell *matCellDef="let index" [formGroupName]="index">
      {{ form.at(index)?.get('costEA')?.value | erpAmount }}
    </td>
  </ng-container>
  <ng-container matColumnDef="cost">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <p i18n="@@sales.materials.cost-ft">Cost (FT)</p>
      <erp-table-filter-icon
        [active]="hasFilter('cost')"
        (click)="onFilterOpen($event, 'cost', 'numeric-decimal-range')"
      ></erp-table-filter-icon>
    </th>
    <td class="with-padding" mat-cell *matCellDef="let index" [formGroupName]="index">
      {{ form.at(index)?.get('cost')?.value | erpAmount }}
    </td>
  </ng-container>
  <ng-container matColumnDef="qty">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <p i18n="@@sales.materials.reserved">Reserved</p>
      <erp-table-filter-icon
        [active]="hasFilter('qty')"
        (click)="onFilterOpen($event, 'qty', 'numeric-decimal-range')"
      ></erp-table-filter-icon>
    </th>
    <td mat-cell *matCellDef="let index" [formGroupName]="index">
      <ng-container *ngIf="editableRow !== index">
        {{ form.at(index)?.get('qty')?.value | erpAmount: getUomFormat(form.at(index)?.get('uomId')?.value) }}
      </ng-container>
      <ng-container *ngIf="editableRow === index">
        <erp-number
          type="decimal"
          formControlName="qty"
          [format]="getUomFormat(form.at(index)?.get('uomId')?.value)"
          (changed)="onReservedQtyChanged(index)"
        >
        </erp-number>
      </ng-container>
    </td>
  </ng-container>
  <ng-container matColumnDef="notes">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <p i18n="@@sales.materials.notes">Notes</p>
      <erp-table-filter-icon
        [active]="hasFilter('notes')"
        (click)="onFilterOpen($event, 'notes', 'string-contains')"
      ></erp-table-filter-icon>
    </th>
    <td class="with-padding" mat-cell *matCellDef="let index" [formGroupName]="index">
      {{ form.at(index)?.get('notes')?.value }}
    </td>
  </ng-container>

  <ng-container matColumnDef="actions" stickyEnd>
    <th mat-header-cell *matHeaderCellDef class="actions"></th>
    <td class="with-padding" mat-cell *matCellDef="let index" [formGroupName]="index">
      <div class="flex justify-content-space-around padding-horizontal-2">
        <ng-container *ngIf="!form.at(index).get('selected')?.value">
          <erp-button variant="icon" color="primary" (click)="onSelectMaterial(index)">
            <p i18n="@@common.buttons.select">Select</p>
          </erp-button>
        </ng-container>
        <ng-container *ngIf="form.at(index).get('selected')?.value">
          <erp-icon icon="action:check" color="primary"></erp-icon>
        </ng-container>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns; sticky: true" color="primary"></tr>
  <tr
    mat-row
    *matRowDef="let index; columns: columns"
    (dblclick)="onEditMaterial(index)"
    (click)="onClickLine(index)"
    [class.erp-row-editable]="editableRow === index"
  ></tr>
</table>

<erp-table-no-results *ngIf="!dataSource.data.length"></erp-table-no-results>

<ng-template #GEN49>
  <ng-container i18n="@@sales.materials.not-enough-available">
    Not enough materials available. You may not be able to reserve that quantity.
  </ng-container>
</ng-template>
