import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';

import { IStockMaterial } from '@erp/shared';

@Component({
  selector: 'erp-sales-sales-quote-materials-allocated-selected-material',
  templateUrl: './sales-quote-materials-allocated-selected-material.component.html',
  styleUrls: ['./sales-quote-materials-allocated-selected-material.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ERPSalesQuoteMaterialsAllocatedSelectedMaterialComponent {
  readonly dataSource = new MatTableDataSource<IStockMaterial>();

  readonly columns: (keyof IStockMaterial)[] = [
    'material',
    'materialType',
    'skuDescription',
    'allocatedFT',
    'allocatedEA',
    'allocatedNT'
  ];

  @Input() set allocatedMaterial(allocatedMaterial: IStockMaterial) {
    this.dataSource.data = [allocatedMaterial];
  }

  get allocatedMaterial() {
    return this.dataSource.data[0];
  }
}
