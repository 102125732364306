<mat-form-field appearance="outline" [@.disabled]="true">
  <input
    [formControl]="control"
    matInput
    readonly
    i18n-placeholder="@@common.form.date.placeholder"
    placeholder="MM/DD/YYYY"
    (focus)="onFocus()"
    (keydown.enter)="onEnterKeyDown($event)"
    (dateChange)="onDateChanged($event)"
    [matDatepicker]="picker"
    [min]="minDate"
    [max]="maxDate"
  />

  <mat-datepicker [touchUi]="touchUi" #picker></mat-datepicker>

  <erp-icon
    disabled
    color="light-gray"
    class="cursor-pointer"
    variant="small"
    *ngIf="clearable && control.value"
    icon="action:cross-circle"
    matSuffix
    (click)="onClear($event)"
  ></erp-icon>

  <erp-icon
    [color]="control.disabled ? 'light-gray' : 'primary'"
    class="cursor-pointer"
    icon="action:calendar"
    matSuffix
    (click)="onFocus()"
  ></erp-icon>

  <mat-hint>
    <ng-content></ng-content>
  </mat-hint>

  <mat-error *ngIf="control.hasError('required')" i18n="@@common.form.errors.required">
    This field is required
  </mat-error>
</mat-form-field>
