import { Observable, skip } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseHttpService } from '../abstracts/http/base-http.service';
import { ICollectionResponse } from '../abstracts/http/collection-response.interface';
import { IPurchaseOrdersListResponse } from '../interfaces/purchase-orders.interface';
import { IDocumentTransition } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ERPSharedPurchaseOrdersService extends BaseHttpService {
  constructor(readonly httpClient: HttpClient) {
    super('PurchaseOrders');
  }

  getPurchaseOrders(
    params: object,
    skipLoader: boolean = false
  ): Observable<ICollectionResponse<IPurchaseOrdersListResponse>> {
    const options = skipLoader ? { headers: { 'skip-loader': 'true' } } : {};
    return this.get<ICollectionResponse<IPurchaseOrdersListResponse>>(params, '', options);
  }

  getAvailableLineStatusTransitions(currentStatusId: number | string) {
    return this.get<IDocumentTransition[]>({ currentStatusId }, 'availableLineStatusTransitions');
  }
}
