import { Observable } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { ISelectOption } from '../../';
import {
  AutoCleanupFeature,
  Dictionary,
  Features,
  IDestroyable,
  IInventorySpecialty,
  ILine,
  IMaterialsFilter,
  IMetadataFiltering,
  TableQueryBuilder
} from '@erp/shared';

const DEBOUNCE_TIME = 500;

@Component({
  selector: 'erp-sales-materials-available-materials-filter',
  templateUrl: './materials-available-materials-filter.component.html',
  styleUrls: ['./materials-available-materials-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([AutoCleanupFeature()])
export class ERPMaterialsAvailableMaterialsFilterComponent<T extends IMaterialsFilter> implements OnInit, IDestroyable {
  readonly destroyed$: Observable<void>;

  readonly form = new UntypedFormGroup({
    materialTypeId: new UntypedFormControl(null),
    sizeId: new UntypedFormControl(null),
    gradeId: new UntypedFormControl(null),
    wallId: new UntypedFormControl(null),
    designId: new UntypedFormControl(null),
    rangeId: new UntypedFormControl(null),
    connectionId: new UntypedFormControl(null),
    threadId: new UntypedFormControl(null),
    conditionId: new UntypedFormControl(null),
    weightPerFoot: new UntypedFormControl(null),
    specialtyId: new UntypedFormControl(null),
    protectorTypeId: new UntypedFormControl(null)
  });

  private filters: IMetadataFiltering<T>[] = [];

  @Input() readonly dictionaries: Dictionary<ISelectOption[]>;
  @Input() readonly line: ILine;
  @Output() readonly filterChanged = new EventEmitter<IMetadataFiltering<T>[]>();

  get filtering() {
    return this.filters;
  }

  @Input() set filtering(filtering: IMetadataFiltering<T>[]) {
    const value = this.modelToViewFiltering(filtering);

    this.form.patchValue(value, { emitEvent: false });

    this.filters = filtering;
  }

  readonly specialtyLabelFn = (specialty: IInventorySpecialty) => specialty.value;

  ngOnInit() {
    this.listenOnValueChanges();
  }

  private listenOnValueChanges() {
    this.form.valueChanges
      .pipe(
        debounceTime(DEBOUNCE_TIME),
        filter(() => this.form.valid),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => {
        const filters = this.form.getRawValue() as T;

        const filtering = this.viewToModelFiltering(filters);

        const builder = TableQueryBuilder.from({
          filtering: this.filtering
        });

        for (const by of Object.keys(filters)) {
          const filterToRemove = { by } as IMetadataFiltering;

          builder.removeFilter(filterToRemove);
        }

        for (const filterToAdd of filtering) {
          builder.setFilter(filterToAdd);
        }

        const query = builder.build();

        this.filters = query.filtering;

        this.filterChanged.emit(query.filtering);
      });
  }

  private modelToViewFiltering(filtering: IMetadataFiltering<T>[]) {
    return filtering
      .map(({ by, match1 }) => ({
        [by]: match1
      }))
      .reduce((base, next) => ({ ...base, ...next }), {});
  }

  private viewToModelFiltering(filters: T) {
    return Object.entries(filters)
      .filter(([, value]) => value !== null)
      .map(
        ([key, value]) =>
          ({
            by: key,
            op: 'eq',
            match1: value
          }) as IMetadataFiltering
      );
  }

  onClearFilters() {
    for (const ctrl in this.form.controls) {
      if (!this.form.get(ctrl)?.disabled) {
        this.form.get(ctrl)?.reset();
      }
    }
  }
}
