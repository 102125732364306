import { Pipe, PipeTransform } from '@angular/core';

import { Dictionary } from '../types';

@Pipe({
  name: 'erpFindProp'
})
export class ERPFindPropPipe implements PipeTransform {
  transform<T>(value: unknown, dictionary: T[], by: string = 'id', target: string = 'value'): T | string | null {
    const entry = dictionary?.find(x => (x as Dictionary)[by] === value);

    return ((entry as Dictionary)?.[target] as string) ?? null;
  }
}
