import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseHttpService } from '../abstracts/http/base-http.service';
import { map, Observable } from 'rxjs';
import { IERPWorkflowProgress, IERPWorkflowStatuses, IInvoiceAvaluableTransitions } from '../interfaces';
import { Cache } from '../decorators';

export const statusMapper = (status: IERPWorkflowStatuses) => ({
  ...status,
  dataInfluence: status.dataInfluence?.map(dataInfluenceItem => ({
    ...dataInfluenceItem,
    properties: (dataInfluenceItem.properties as string).split(';')
  }))
});

@Injectable({
  providedIn: 'root'
})
export class ERPSharedWorkflowService extends BaseHttpService {
  constructor(readonly httpClient: HttpClient) {
    super('workflowBrowse');
  }

  @Cache()
  invoiceWorkflowStatuses(): Observable<IERPWorkflowStatuses[]> {
    return this.get<IERPWorkflowStatuses[]>({}, 'types/8/statuses').pipe(map(statuses => statuses.map(statusMapper)));
  }

  @Cache()
  purchaseOrderWorkflowStatuses(): Observable<IERPWorkflowStatuses[]> {
    return this.get<IERPWorkflowStatuses[]>({}, 'types/5/statuses').pipe(map(statuses => statuses.map(statusMapper)));
  }

  @Cache()
  purchaseOrderWorkflowStatusesDistiantNames(): Observable<IERPWorkflowStatuses[]> {
    return this.get<IERPWorkflowStatuses[]>({}, 'types/5/distinctNames');
  }

  getAvailableStatusesByDocumentId(currentStatusId: number, invId: number): Observable<IInvoiceAvaluableTransitions[]> {
    const params: { [key: string]: string | number | boolean } = {};
    params.currentStatusId = currentStatusId;
    params.includeDisabled = false;

    return this.get<IInvoiceAvaluableTransitions[]>(params, `${invId}/availableTransitions`);
  }

  getAvailableStatusesByStatusId(currentStatusId: number): Observable<IInvoiceAvaluableTransitions[]> {
    const params: { [key: string]: string | number | boolean } = {};
    params.currentStatusId = currentStatusId;

    return this.get<IInvoiceAvaluableTransitions[]>(params, 'availableStatuses');
  }

  getWorkflowProgressByDocumentId(invId: number): Observable<IERPWorkflowProgress> {
    return this.get<IERPWorkflowProgress>({}, `${invId}/progress`);
  }
}
