import { NgModule } from '@angular/core';
import { ERPDocumentStatusChangeConfirm } from './document-status-change-confirm.component';
import { ERPButtonModule, ERPIconModule, ERPLabelModule, ERPTextareaModule } from '../../index';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

@NgModule({
  declarations: [ERPDocumentStatusChangeConfirm],
  imports: [
    ERPButtonModule,
    ERPIconModule,
    ERPLabelModule,
    ERPTextareaModule,
    ReactiveFormsModule,
    CommonModule,
    MatDialogModule
  ]
})
export class ERPDocumentStatusChangeConfirmModule {}
