<div class="col-6 flex align-items-center margin-bottom-2">
  <erp-button dense variant="link" color="primary" (click)="onClearFilters()">
    <ng-container i18n="@@common.buttons.clear-filters"> Clear Filters </ng-container>
  </erp-button>
</div>
<div class="grid filter-grid grid-column-gap-d" [formGroup]="form">
  <div class="col-auto">
    <erp-label i18n="@@sales.materials.filter.material-type.label"> Material Type: </erp-label>
    <erp-select formControlName="materialTypeId" [options]="dictionaries.materialTypes" [optional]="true"></erp-select>
  </div>
  <div class="col-auto">
    <erp-label i18n="@@sales.materials.filter.wall.label"> Wall: </erp-label>
    <erp-select formControlName="wallId" [options]="dictionaries.walls" [optional]="true"></erp-select>
  </div>
  <div class="col-auto">
    <erp-label i18n="@@sales.materials.filter.connection.label"> Connection: </erp-label>
    <erp-select formControlName="connectionId" [options]="dictionaries.connections" [optional]="true"></erp-select>
  </div>
  <div class="col-auto">
    <erp-label i18n="@@sales.materials.filter.weight-per-foot.label"> Weight per Foot: </erp-label>
    <erp-number type="decimal" formControlName="weightPerFoot"></erp-number>
  </div>
  <div class="col-auto">
    <erp-label i18n="@@sales.materials.filter.protector-type.label"> Protector Type: </erp-label>
    <erp-select
      formControlName="protectorTypeId"
      [options]="dictionaries.protectorTypes"
      [optional]="true"
    ></erp-select>
  </div>
  <div class="col-auto">
    <erp-label i18n="@@sales.materials.filter.size.label"> OD: </erp-label>
    <erp-select formControlName="sizeId" [options]="dictionaries.sizes" [optional]="true"></erp-select>
  </div>
  <div class="col-auto">
    <erp-label i18n="@@sales.materials.filter.design.label"> Design: </erp-label>
    <erp-select formControlName="designId" [options]="dictionaries.designs" [optional]="true"></erp-select>
  </div>
  <div class="col-auto">
    <erp-label i18n="@@sales.materials.filter.end-type.label"> End type: </erp-label>
    <erp-select formControlName="threadId" [options]="dictionaries.threads" [optional]="true"></erp-select>
  </div>
  <div class="col-auto">
    <erp-label i18n="@@sales.materials.filter.specialty.label"> Specialty: </erp-label>
    <erp-select
      formControlName="specialtyId"
      [options]="dictionaries.specialties"
      [labelFn]="specialtyLabelFn"
      [optional]="true"
    ></erp-select>
  </div>
  <div class="col-auto">
    <erp-label i18n="@@sales.materials.filter.grade.label"> Grade: </erp-label>
    <erp-select formControlName="gradeId" [options]="dictionaries.grades" [optional]="true"></erp-select>
  </div>
  <div class="col-auto">
    <erp-label i18n="@@sales.materials.filter.range.label"> Range: </erp-label>
    <erp-select formControlName="rangeId" [options]="dictionaries.ranges" [optional]="true"></erp-select>
  </div>
  <div class="col-auto">
    <erp-label i18n="@@sales.materials.filter.condition.label"> Condition: </erp-label>
    <erp-select formControlName="conditionId" [options]="dictionaries.conditions" [optional]="true"></erp-select>
  </div>
</div>
