import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { ERPTableModule } from '../table/table.module';
import { ERPButtonModule } from '../button/button.module';
import { ERPIconModule } from '../icon/icon.module';
import { ERPLabelModule } from '../label/label.module';
import { ERPSelectModule } from '../select/select.module';
import { ERPSideDialogModule } from '../side-dialog/side-dialog.module';
import { ERPNumberModule } from '../number/number.module';
import { ERPPaginatorModule } from '../paginator/paginator.module';
import { ERPTooltipModule } from '../tooltip/tooltip.module';

import { ERPSharedModule } from '@erp/shared';

import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

import { ERPSalesQuoteMaterialsAllocatedMaterialComponent } from './sales-quote-materials-allocated-material';
import { ERPMaterialsAvailableMaterialsFilterComponent } from './materials-available-materials-filter';
import { ERPMaterialsAvailableMaterialsTableComponent } from './materials-available-materials-table';
import { ERPSalesQuoteMaterialsAllocatedSelectedMaterialComponent } from './sales-quote-materials-allocated-selected-material';
import { ERPSalesQuoteMaterialsAllocatedTransactionsTableComponent } from './sales-quote-materials-allocated-transactions-table';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    ERPSideDialogModule,
    ERPButtonModule,
    ERPIconModule,
    ERPLabelModule,
    ERPSelectModule,
    ERPTableModule,
    ERPNumberModule,
    ERPPaginatorModule,
    ERPTooltipModule,
    ERPSharedModule,
    MatDialogModule
  ],
  declarations: [
    ERPSalesQuoteMaterialsAllocatedMaterialComponent,
    ERPMaterialsAvailableMaterialsFilterComponent,
    ERPMaterialsAvailableMaterialsTableComponent,
    ERPSalesQuoteMaterialsAllocatedSelectedMaterialComponent,
    ERPSalesQuoteMaterialsAllocatedTransactionsTableComponent
  ],
  exports: [
    ERPSalesQuoteMaterialsAllocatedMaterialComponent,
    ERPMaterialsAvailableMaterialsFilterComponent,
    ERPMaterialsAvailableMaterialsTableComponent
  ]
})
export class ERPFifoTableModule {}
