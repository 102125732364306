import { Injectable } from '@angular/core';

import { DictionaryService, ERPSharedWorkflowService } from '../services';

@Injectable({ providedIn: 'root' })
export class ERPPoStatusesResolver {
  constructor(
    readonly dictionaryService: DictionaryService,
    private workflow: ERPSharedWorkflowService
  ) {}

  resolve() {
    return this.workflow.purchaseOrderWorkflowStatusesDistiantNames();
  }
}
