import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';

import { ERPSharedModule } from '../../../../../shared/src/lib';

import { ERPButtonModule } from '../button/button.module';
import { ERPIconModule } from '../icon';
import { ERPReadareaComponent } from './readarea.component';

@NgModule({
  declarations: [ERPReadareaComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    MatFormFieldModule,
    MatInputModule,
    ERPSharedModule,
    ERPButtonModule,
    ERPIconModule
  ],
  exports: [ERPReadareaComponent, MatFormFieldModule, ReactiveFormsModule]
})
export class ERPReadareaModule {}
