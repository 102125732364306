<h1 class="mat-headline-5 dialog-header margin-right-5" mat-dialog-title>
  <ng-container i18n="@@logistic.loading-report.popup.header">Select parameters</ng-container>
</h1>
<ng-container>
  <erp-button color="primary" variant="icon" class="dialog-close" mat-dialog-close>
    <erp-icon icon="action:cross"></erp-icon>
  </erp-button>
</ng-container>
<section class="dialog-content" mat-dialog-content>
  <div [formGroup]="form" class="padding-top-3">
    <ng-container [ngSwitch]="this.data.type">
      <!-- shipping -->
      <ng-container *ngSwitchCase="loadingReportEnum.SHIPPING">
        <div>
          <erp-label class="margin-bottom-1">
            <ng-container i18n="@@logistic.loading-report.popup.sales-order"> Sales Order: </ng-container>
          </erp-label>
          <erp-autocomplete
            icon="action:search"
            formControlName="salesOrderNumber"
            [autoSelect]="true"
            [optionsFn]="salesOrderNumberOptionsFn"
            [displayFn]="salesOrderNumberDisplayFn"
            [labelFn]="salesOrderNumberLabelFn"
            [valueFn]="salesOrderNumberValueFn"
            (changed)="onSalesOrderChange($event)"
          ></erp-autocomplete>
        </div>
        <div>
          <erp-label class="margin-bottom-1">
            <ng-container i18n="@@logistic.loading-report.popup.material-release"> Material Release: </ng-container>
          </erp-label>
          <erp-autocomplete
            icon="action:search"
            formControlName="materialReleaseNumber"
            [autoSelect]="true"
            [optionsFn]="materialReleaseOptionsFn"
            [displayFn]="materialReleaseDisplayFn"
            [labelFn]="materialReleaseLabelFn"
            [valueFn]="materialReleaseValueFn"
            (changed)="onMaterialReleaseChange($event)"
          ></erp-autocomplete>
        </div>
        <div>
          <erp-label class="margin-bottom-1">
            <ng-container i18n="@@logistic.loading-report.popup.customer-name"> Customer Name: </ng-container>
          </erp-label>
          <erp-autocomplete
            formControlName="customerName"
            icon="action:search"
            [autoSelect]="true"
            (changed)="onCustomerNameChanged($event)"
            [optionsFn]="customerOptionsFn"
            [labelFn]="customerLabelFn"
            [displayFn]="customerLabelFn"
          ></erp-autocomplete>
        </div>
        <erp-label class="margin-bottom-1">
          <ng-container i18n="@@logistic.loading-report.popup.shipping-date">Shipping Date:</ng-container>
        </erp-label>
        <div class="grid margin-bottom-3">
          <erp-date-picker
            class="col-5"
            formControlName="from"
            [clearable]="true"
            [min]="minDate"
            [max]="maxDate"
          ></erp-date-picker>
          <div class="text-center col-2">_</div>
          <erp-date-picker
            class="col-5"
            formControlName="to"
            [clearable]="true"
            [min]="minDate"
            [max]="maxDate"
          ></erp-date-picker>
          <mat-error class="mat-caption col-12" *ngIf="form.hasError('range')" i18n="@@common.form.errors.range-date">
            'End date' must be greater than 'Start date'
          </mat-error>
        </div>
      </ng-container>

      <!-- receiving -->
      <ng-container *ngSwitchCase="loadingReportEnum.RECEIVING">
        <div>
          <erp-label class="margin-bottom-1">
            <ng-container i18n="@@logistic.loading-report.popup.purchase-order"> Purchase Order: </ng-container>
          </erp-label>
          <erp-autocomplete
            icon="action:search"
            formControlName="purchaseOrder"
            [autoSelect]="true"
            [optionsFn]="poOptionsFn"
            [displayFn]="purchaseOrderLabelFn"
            [labelFn]="purchaseOrderLabelFn"
            (changed)="onPurchaseOrderChange($event)"
          ></erp-autocomplete>
        </div>
        <div>
          <erp-label class="margin-bottom-1">
            <ng-container i18n="@@logistic.loading-report.popup.inbound-delivery"> Inbound Delivery: </ng-container>
          </erp-label>
          <erp-autocomplete
            icon="action:search"
            formControlName="inboundDelivery"
            [autoSelect]="true"
            [optionsFn]="inboundDeliveryOptionsFn"
            [displayFn]="inboundDeliveryLabelFn"
            [labelFn]="inboundDeliveryLabelFn"
            (changed)="onInboundDeliveryChange($event)"
          ></erp-autocomplete>
        </div>
        <div>
          <erp-label class="margin-bottom-1">
            <ng-container i18n="@@logistic.loading-report.popup.customer-name"> Customer Name: </ng-container>
          </erp-label>
          <erp-autocomplete
            formControlName="customerName"
            icon="action:search"
            [autoSelect]="true"
            (changed)="onCustomerNameChanged($event)"
            [optionsFn]="customerOptionsFn"
            [labelFn]="customerLabelFn"
            [displayFn]="customerLabelFn"
          ></erp-autocomplete>
        </div>
        <erp-label class="margin-bottom-1">
          <ng-container i18n="@@logistic.loading-report.popup.receiving-date">Receiving Date:</ng-container>
        </erp-label>
        <div class="grid margin-bottom-3">
          <erp-date-picker
            class="col-5"
            formControlName="from"
            [clearable]="true"
            [min]="minDate"
            [max]="maxDate"
          ></erp-date-picker>
          <div class="text-center col-2">_</div>
          <erp-date-picker
            class="col-5"
            formControlName="to"
            [clearable]="true"
            [min]="minDate"
            [max]="maxDate"
          ></erp-date-picker>
          <mat-error class="mat-caption col-12" *ngIf="form.hasError('range')" i18n="@@common.form.errors.range-date">
            'End date' must be greater than 'Start date'
          </mat-error>
        </div>
      </ng-container>

      <!-- shipping-package -->
      <ng-container *ngSwitchCase="loadingReportEnum.SHIPPING_PACKAGE">
        <div>
          <erp-label [required]="true" class="margin-bottom-1">
            <ng-container i18n="@@logistic.loading-report.popup.customer"> Customer: </ng-container>
          </erp-label>
          <erp-autocomplete
            icon="action:search"
            formControlName="customerName"
            [autoSelect]="true"
            [optionsFn]="customerOptionsFn"
            [labelFn]="customerLabelFn"
            [displayFn]="customerLabelFn"
            (changed)="onCustomerChanged($event)"
          ></erp-autocomplete>
        </div>
        <div>
          <erp-label class="margin-bottom-1">
            <ng-container i18n="@@logistic.loading-report.popup.customer-release"> Customer Release: </ng-container>
          </erp-label>
          <erp-autocomplete
            icon="action:search"
            formControlName="customerReleaseNumber"
            [autoSelect]="true"
            [optionsFn]="customerReleaseOptionsFn"
          ></erp-autocomplete>
        </div>
        <div>
          <erp-label class="margin-bottom-1">
            <ng-container i18n="@@logistic.loading-report.popup.material-id"> Material ID: </ng-container>
          </erp-label>
          <erp-autocomplete
            icon="action:search"
            formControlName="materialId"
            [autoSelect]="true"
            [optionsFn]="materialsOptionsFn"
            [displayFn]="materialLabelFn"
            [labelFn]="materialLabelFn"
          ></erp-autocomplete>
        </div>
        <div>
          <erp-label class="margin-bottom-1">
            <ng-container i18n="@@logistic.loading-report.popup.destination"> Destination: </ng-container>
          </erp-label>
          <erp-autocomplete
            icon="action:search"
            formControlName="destination"
            [autoSelect]="true"
            [optionsFn]="destinationOptionsFn"
          ></erp-autocomplete>
        </div>
        <erp-label class="margin-bottom-1">
          <ng-container i18n="@@logistic.loading-report.popup.shipping-date">Shipping Date:</ng-container>
        </erp-label>
        <div class="grid margin-bottom-3">
          <erp-date-picker
            class="col-5"
            formControlName="from"
            [clearable]="true"
            [min]="minDate"
            [max]="maxDate"
          ></erp-date-picker>
          <div class="text-center col-2">_</div>
          <erp-date-picker
            class="col-5"
            formControlName="to"
            [clearable]="true"
            [min]="minDate"
            [max]="maxDate"
          ></erp-date-picker>
          <mat-error class="mat-caption col-12" *ngIf="form.hasError('range')" i18n="@@common.form.errors.range-date">
            'End date' must be greater than 'Start date'
          </mat-error>
        </div>
      </ng-container>

      <!-- Storage Report -->
      <ng-container *ngSwitchCase="loadingReportEnum.STORAGE_REPORT">
        <div>
          <erp-label [required]="true" class="margin-bottom-1">
            <ng-container i18n="@@logistic.loading-report.popup.customer"> Customer: </ng-container>
          </erp-label>
          <erp-autocomplete
            icon="action:search"
            formControlName="customerName"
            [autoSelect]="true"
            [optionsFn]="customerOptionsFn"
            [labelFn]="customerLabelFn"
            [displayFn]="customerLabelFn"
            (changed)="onCustomerChanged($event)"
          ></erp-autocomplete>
        </div>
        <div>
          <erp-label [required]="true" class="margin-bottom-1">
            <ng-container i18n="@@logistic.loading-report.popup.grace-period"> Grace Period (Month): </ng-container>
          </erp-label>
          <erp-number class="col-12" type="integer" [format]="'0'" formControlName="gracePeriod"></erp-number>
        </div>
        <div>
          <erp-label [required]="true" class="margin-bottom-1">
            <ng-container i18n="@@logistic.loading-report.popup.price-per-nt"> Price Per NT: </ng-container>
          </erp-label>
          <erp-number class="col-12" type="decimal" [format]="'0.00'" formControlName="pricePerTon"></erp-number>
        </div>
      </ng-container>

      <!-- Storage Report FTZ -->
      <ng-container *ngSwitchCase="loadingReportEnum.STORAGE_REPORT_FTZ">
        <div>
          <erp-label [required]="true" class="margin-bottom-1">
            <ng-container i18n="@@logistic.loading-report.popup.customer"> Customer: </ng-container>
          </erp-label>
          <erp-autocomplete
            icon="action:search"
            formControlName="customerName"
            [autoSelect]="true"
            [optionsFn]="customerOptionsFn"
            [labelFn]="customerLabelFn"
            [displayFn]="customerLabelFn"
            (changed)="onCustomerChanged($event)"
          ></erp-autocomplete>
        </div>
        <div>
          <erp-label [required]="true" class="margin-bottom-1">
            <ng-container i18n="@@logistic.loading-report.popup.grace-period"> Grace Period (Month): </ng-container>
          </erp-label>
          <erp-number class="col-12" type="integer" [format]="'0'" formControlName="gracePeriod"></erp-number>
        </div>
        <div>
          <erp-label [required]="true" class="margin-bottom-1">
            <ng-container i18n="@@logistic.loading-report.popup.price-per-nt"> Price Per NT: </ng-container>
          </erp-label>
          <erp-number class="col-12" type="decimal" [format]="'0.00'" formControlName="pricePerTon"></erp-number>
        </div>
      </ng-container>
    </ng-container>
  </div>
</section>
<section class="flex justify-content-flex-end" mat-dialog-actions>
  <erp-button color="primary" class="margin-right-4" variant="link" mat-dialog-close>
    <ng-container i18n="@@common.buttons.cancel"> Cancel </ng-container>
  </erp-button>
  <erp-button [disabled]="!form.valid" class="dialog-action-confirm" color="accent" (click)="onCreate()">
    <ng-container i18n="@@common.buttons.create"> Create </ng-container>
  </erp-button>
</section>
