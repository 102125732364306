import { Observable } from 'rxjs';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';

import { ERPTableFilterOpener, ISelectOption } from '../../';
import {
  AutoCleanupFeature,
  BaseTableComponent,
  Features,
  IDestroyable,
  ISalesQuoteAllocatedMaterial,
  IdValue,
  ModulesLinks
} from '@erp/shared';

import { ERPSalesQuoteMaterialsAllocatedMaterialComponent } from '../sales-quote-materials-allocated-material';

@Component({
  selector: 'erp-sales-sales-quote-materials-allocated-transactions-table',
  templateUrl: './sales-quote-materials-allocated-transactions-table.component.html',
  styleUrls: ['./sales-quote-materials-allocated-transactions-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: []
})
@Features([AutoCleanupFeature()])
export class ERPSalesQuoteMaterialsAllocatedTransactionsTableComponent
  extends BaseTableComponent<ISalesQuoteAllocatedMaterial>
  implements OnInit, IDestroyable
{
  @Input() readonly dialogRef: MatDialogRef<
    ERPSalesQuoteMaterialsAllocatedMaterialComponent<ISalesQuoteAllocatedMaterial>
  >;

  readonly destroyed$: Observable<void>;

  @Input() readonly salesOrStockList: IdValue[] = [];
  @Input() salesDocumentTypes: ISelectOption[];

  readonly dataSource = new MatTableDataSource<ISalesQuoteAllocatedMaterial>();
  readonly columns: (keyof ISalesQuoteAllocatedMaterial)[] = [
    'documentNumber',
    'salesDocumentTypeName',
    'qty',
    'uom',
    'ownerName',
    'salesOrStock'
  ];
  readonly processingOrderTypeId = 3;
  readonly materialReleaseTypeId = 17;
  readonly salesOrderTypeId = 2;
  readonly transferOrdersTypeId = 8;

  readonly links = new Map<number, string>([
    [this.transferOrdersTypeId, ModulesLinks.TransferOrders],
    [this.processingOrderTypeId, ModulesLinks.ProcessingOrder],
    [this.materialReleaseTypeId, ModulesLinks.MaterialRelease],
    [this.salesOrderTypeId, ModulesLinks.SalesOrder]
  ]);

  constructor(
    readonly changeDetector: ChangeDetectorRef,
    readonly tableFilterOpener: ERPTableFilterOpener,
    readonly router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.onInit();
  }

  getLink(documentTypeId: number): string | undefined {
    return documentTypeId ? this.links.get(documentTypeId) : '';
  }
}
