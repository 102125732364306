import { DecimalPipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'erpAmount'
})
export class ERPAmountPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private _locale: string) {}

  transform(value?: string | number | null, digitsInfo?: string, locale?: string): string | null | undefined {
    let digitRules;

    if (digitsInfo) {
      const param = digitsInfo.split('.')[1]?.length ?? 0;

      digitRules = `.${param}-${param}`;
    }
    try {
      return new DecimalPipe(this._locale).transform(
        value as string | number | null | undefined,
        digitRules ?? '.2-2',
        locale
      ) as string | null | undefined;
    } catch {
      return value as string | null | undefined;
    }
  }
}
