<div class="" style="height: 100%" *ngIf="headers$ | async as headers">
  <erp-button #refreshButton class="refresh-button" variant="link" [color]="undefined" (click)="onRefreshDashboard()">
    <erp-icon icon="action:refresh"></erp-icon>
    <ng-container i18n="@@common.buttons.refresh">Refresh</ng-container>
  </erp-button>
  <dx-dashboard-control
    #dashboardControl
    [workingMode]="'ViewerOnly'"
    [dashboardId]="id$ | async"
    [loadDefaultDashboard]="false"
    style="display: block; width: 100%; height: 100%"
    [endpoint]="endpoint"
    (onDashboardInitialized)="onDashboardInitialized($event)"
  >
    <dxo-ajax-remote-service [headers]="headers" [complete]="requestComplete"> </dxo-ajax-remote-service>
    <dxo-data-request-options
      itemDataRequestMode="BatchRequests"
      itemDataLoadingMode="OnDemand"
    ></dxo-data-request-options>
  </dx-dashboard-control>
</div>
