<table mat-table erpResizeTable border-collapse [dataSource]="dataSource">
  <ng-container matColumnDef="material">
    <th mat-header-cell *matHeaderCellDef>
      <p i18n="@@sales.sales-quotes.allocated-material.material">Material ID</p>
    </th>
    <td mat-cell *matCellDef="let allocatedMaterial">
      {{ allocatedMaterial.material.materialNumber }}
    </td>
  </ng-container>
  <ng-container matColumnDef="materialType">
    <th mat-header-cell *matHeaderCellDef>
      <p i18n="@@sales.sales-quotes.allocated-material.materialType">Material Type</p>
    </th>
    <td mat-cell *matCellDef="let allocatedMaterial">
      {{ allocatedMaterial.materialType.value }}
    </td>
  </ng-container>
  <ng-container matColumnDef="skuDescription">
    <th mat-header-cell *matHeaderCellDef>
      <p i18n="@@sales.sales-quotes.allocated-material.sku-description">SKU Description</p>
    </th>
    <td mat-cell *matCellDef="let allocatedMaterial">
      {{ allocatedMaterial.skuDescription }}
    </td>
  </ng-container>
  <ng-container matColumnDef="allocatedFT">
    <th mat-header-cell *matHeaderCellDef>
      <p i18n="@@sales.sales-quotes.allocated-material.allocatedFT">Allocated (FT)</p>
    </th>
    <td mat-cell *matCellDef="let allocatedMaterial">{{ allocatedMaterial.allocatedFT }}</td>
  </ng-container>
  <ng-container matColumnDef="allocatedEA">
    <th mat-header-cell *matHeaderCellDef>
      <p i18n="@@sales.sales-quotes.allocated-material.allocatedEA">Allocated (EA)</p>
    </th>
    <td mat-cell *matCellDef="let allocatedMaterial">{{ allocatedMaterial.allocatedEA }}</td>
  </ng-container>
  <ng-container matColumnDef="allocatedNT">
    <th mat-header-cell *matHeaderCellDef>
      <p i18n="@@sales.sales-quotes.allocated-material.allocatedNT">Allocated (NT)</p>
    </th>
    <td mat-cell *matCellDef="let allocatedMaterial">{{ allocatedMaterial.allocatedNT }}</td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="columns" color="light-gray"></tr>
  <tr mat-row *matRowDef="let row; columns: columns"></tr>
</table>
