import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'erp-side-dialog',
  templateUrl: './side-dialog.component.html',
  styleUrls: ['./side-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ERPSideDialogComponent<T extends object> implements OnInit {
  @Input() readonly closable: boolean;
  @Input() readonly footerBorder: boolean;

  constructor(readonly dialogRef: MatDialogRef<T>) {}

  ngOnInit() {
    this.setDialogPosition();
  }

  private setDialogPosition() {
    this.dialogRef.addPanelClass('erp-side-dialog');

    this.dialogRef.updatePosition({
      top: CSS.px(0).toString(),
      right: CSS.px(0).toString()
    });

    this.dialogRef.updateSize(undefined, CSS.vh(100).toString());
  }
}
